import Logo from "./Logo";
import Button from "react-bootstrap/Button";

const Hero = () => {
	return (
		<section className="hero">
			<div className="hero-bg" />
			<div className="hero-content flex-column">
				<Logo className="hero-logo" />
				<span className="hero-title text-bold-sora">Your Audience</span>
				<span className="hero-title text-bold-sora">Your Platform</span>
				<p className="hero-text text-sm text-regular-inter align-center opacity-7">
					Pariatur quo et sit doloribus enim. Rerum eum repudiandae voluptate
					atque est voluptatem esse et voluptatibus suscipit ducimus laborum cum
					non
				</p>
				<Button
					variant="light"
					className="get-started-btn rounded text-medium-sora text-sm bg-white"
				>
					Get Started
				</Button>
			</div>
		</section>
	);
};

export default Hero;
