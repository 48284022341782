const Logo = ({ className }) => {
	return (
		<svg className={className} viewBox="70 70 800 150" fill="none">
			<rect fill="none" />
			<path
				d="M157.238 118.853C173.726 102.365 192.577 106.884 203.518 113.196C206.504 114.919 206.697 118.938 204.259 121.376L144.52 181.115C128.032 197.603 109.181 193.084 98.24 186.773C95.2535 185.05 95.0612 181.03 97.4992 178.592L157.238 118.853Z"
				fill="#FD9A39"
			/>
			<path
				d="M138.493 121.031C140.781 123.318 140.781 127.027 138.493 129.315L93.3573 174.45C90.9194 176.888 86.8998 176.696 85.1769 173.71C78.8651 162.769 74.3463 143.918 90.8346 127.43L113.721 104.543C116.009 102.255 119.718 102.255 122.005 104.543L138.493 121.031Z"
				fill="#F65854"
			/>
			<path
				d="M179.721 195.457C182.009 197.744 185.717 197.744 188.005 195.457L210.923 172.539C227.411 156.05 222.893 137.2 216.581 126.259C214.858 123.272 210.838 123.08 208.4 125.518L163.233 170.685C160.946 172.973 160.946 176.681 163.233 178.969L179.721 195.457Z"
				fill="#27B3E8"
			/>
			<path
				d="M284.079 180.9C277.279 180.9 271.546 179.833 266.879 177.7C262.279 175.567 258.779 172.667 256.379 169C254.046 165.267 252.879 161 252.879 156.2H270.879C270.879 157.933 271.346 159.533 272.279 161C273.279 162.4 274.746 163.5 276.679 164.3C278.612 165.1 281.079 165.5 284.079 165.5C286.679 165.5 288.879 165.167 290.679 164.5C292.546 163.833 293.946 162.9 294.879 161.7C295.879 160.5 296.379 159.067 296.379 157.4C296.379 155.267 295.479 153.633 293.679 152.5C291.879 151.3 288.846 150.533 284.579 150.2L279.879 149.8C272.346 149.133 266.346 146.767 261.879 142.7C257.412 138.633 255.179 133.233 255.179 126.5C255.179 121.7 256.279 117.6 258.479 114.2C260.746 110.8 263.912 108.2 267.979 106.4C272.046 104.6 276.846 103.7 282.379 103.7C288.379 103.7 293.446 104.7 297.579 106.7C301.779 108.7 304.979 111.533 307.179 115.2C309.379 118.8 310.479 123 310.479 127.8H292.479C292.479 126.2 292.079 124.733 291.279 123.4C290.479 122.067 289.312 121.033 287.779 120.3C286.312 119.5 284.512 119.1 282.379 119.1C280.379 119.1 278.679 119.433 277.279 120.1C275.946 120.7 274.912 121.567 274.179 122.7C273.512 123.833 273.179 125.1 273.179 126.5C273.179 128.367 273.812 129.967 275.079 131.3C276.346 132.633 278.546 133.433 281.679 133.7L286.379 134.1C291.846 134.567 296.679 135.767 300.879 137.7C305.079 139.567 308.379 142.133 310.779 145.4C313.179 148.667 314.379 152.667 314.379 157.4C314.379 162.133 313.146 166.267 310.679 169.8C308.279 173.333 304.812 176.067 300.279 178C295.812 179.933 290.412 180.9 284.079 180.9Z"
				fill="#050911"
			/>
			<path
				d="M341.697 180.7C335.43 180.7 330.564 178.667 327.097 174.6C323.697 170.467 321.997 164.167 321.997 155.7V123.7H340.197V156.2C340.197 158.667 340.93 160.667 342.397 162.2C343.864 163.733 345.83 164.5 348.297 164.5C350.764 164.5 352.764 163.733 354.297 162.2C355.83 160.667 356.597 158.6 356.597 156V123.7H374.797V178.9H360.397V155.7H361.397C361.397 161.367 360.664 166.067 359.197 169.8C357.797 173.467 355.697 176.2 352.897 178C350.097 179.8 346.63 180.7 342.497 180.7H341.697Z"
				fill="#050911"
			/>
			<path
				d="M388.236 198.9V123.7H402.636V147.5H401.036C401.236 142.1 402.303 137.5 404.236 133.7C406.236 129.9 408.936 127.033 412.336 125.1C415.736 123.1 419.636 122.1 424.036 122.1C427.836 122.1 431.269 122.8 434.336 124.2C437.403 125.533 440.036 127.467 442.236 130C444.436 132.467 446.103 135.4 447.236 138.8C448.436 142.2 449.036 145.933 449.036 150V152.4C449.036 156.467 448.503 160.233 447.436 163.7C446.369 167.1 444.769 170.1 442.636 172.7C440.503 175.233 437.869 177.2 434.736 178.6C431.669 180 428.136 180.7 424.136 180.7C419.869 180.7 416.103 179.867 412.836 178.2C409.569 176.467 406.969 173.9 405.036 170.5C403.169 167.033 402.169 162.7 402.036 157.5L406.436 162.9V198.9H388.236ZM418.636 165.7C421.036 165.7 423.136 165.1 424.936 163.9C426.803 162.633 428.236 160.9 429.236 158.7C430.303 156.5 430.836 154 430.836 151.2C430.836 148.333 430.303 145.867 429.236 143.8C428.236 141.667 426.836 140.033 425.036 138.9C423.236 137.7 421.103 137.1 418.636 137.1C416.436 137.1 414.369 137.6 412.436 138.6C410.569 139.6 409.036 141.067 407.836 143C406.636 144.933 406.036 147.267 406.036 150V153.4C406.036 156 406.669 158.233 407.936 160.1C409.203 161.9 410.803 163.3 412.736 164.3C414.669 165.233 416.636 165.7 418.636 165.7Z"
				fill="#050911"
			/>
			<path
				d="M484.937 180.9C480.27 180.9 476.137 180.1 472.537 178.5C468.937 176.9 465.903 174.733 463.437 172C460.97 169.267 459.07 166.2 457.737 162.8C456.47 159.4 455.837 155.9 455.837 152.3V150.3C455.837 146.567 456.47 143 457.737 139.6C459.07 136.133 460.937 133.067 463.337 130.4C465.803 127.733 468.803 125.633 472.337 124.1C475.87 122.5 479.87 121.7 484.337 121.7C490.27 121.7 495.303 123.033 499.437 125.7C503.57 128.367 506.737 131.9 508.937 136.3C511.137 140.633 512.237 145.367 512.237 150.5V156.3H463.437V146.5H501.737L495.437 150.9C495.437 147.767 495.003 145.133 494.137 143C493.337 140.8 492.103 139.133 490.437 138C488.837 136.867 486.803 136.3 484.337 136.3C481.937 136.3 479.837 136.867 478.037 138C476.303 139.067 474.97 140.733 474.037 143C473.103 145.2 472.637 148 472.637 151.4C472.637 154.467 473.07 157.133 473.937 159.4C474.803 161.6 476.137 163.3 477.937 164.5C479.737 165.7 482.07 166.3 484.937 166.3C487.47 166.3 489.537 165.867 491.137 165C492.803 164.133 493.97 162.967 494.637 161.5H511.237C510.437 165.233 508.837 168.567 506.437 171.5C504.103 174.433 501.103 176.733 497.437 178.4C493.837 180.067 489.67 180.9 484.937 180.9Z"
				fill="#050911"
			/>
			<path
				d="M548.63 180.9C541.83 180.9 536.096 179.833 531.43 177.7C526.83 175.567 523.33 172.667 520.93 169C518.596 165.267 517.43 161 517.43 156.2H535.43C535.43 157.933 535.896 159.533 536.83 161C537.83 162.4 539.296 163.5 541.23 164.3C543.163 165.1 545.63 165.5 548.63 165.5C551.23 165.5 553.43 165.167 555.23 164.5C557.096 163.833 558.496 162.9 559.43 161.7C560.43 160.5 560.93 159.067 560.93 157.4C560.93 155.267 560.03 153.633 558.23 152.5C556.43 151.3 553.396 150.533 549.13 150.2L544.43 149.8C536.896 149.133 530.896 146.767 526.43 142.7C521.963 138.633 519.73 133.233 519.73 126.5C519.73 121.7 520.83 117.6 523.03 114.2C525.296 110.8 528.463 108.2 532.53 106.4C536.596 104.6 541.396 103.7 546.93 103.7C552.93 103.7 557.996 104.7 562.13 106.7C566.33 108.7 569.53 111.533 571.73 115.2C573.93 118.8 575.03 123 575.03 127.8H557.03C557.03 126.2 556.63 124.733 555.83 123.4C555.03 122.067 553.863 121.033 552.33 120.3C550.863 119.5 549.063 119.1 546.93 119.1C544.93 119.1 543.23 119.433 541.83 120.1C540.496 120.7 539.463 121.567 538.73 122.7C538.063 123.833 537.73 125.1 537.73 126.5C537.73 128.367 538.363 129.967 539.63 131.3C540.896 132.633 543.096 133.433 546.23 133.7L550.93 134.1C556.396 134.567 561.23 135.767 565.43 137.7C569.63 139.567 572.93 142.133 575.33 145.4C577.73 148.667 578.93 152.667 578.93 157.4C578.93 162.133 577.696 166.267 575.23 169.8C572.83 173.333 569.363 176.067 564.83 178C560.363 179.933 554.963 180.9 548.63 180.9Z"
				fill="#050911"
			/>
			<path
				d="M612.948 180.9C608.148 180.9 603.981 180.133 600.448 178.6C596.914 177 593.948 174.867 591.548 172.2C589.214 169.533 587.448 166.5 586.248 163.1C585.114 159.7 584.548 156.167 584.548 152.5V150.5C584.548 146.633 585.148 142.967 586.348 139.5C587.614 136.033 589.448 132.967 591.848 130.3C594.248 127.633 597.214 125.533 600.748 124C604.281 122.467 608.348 121.7 612.948 121.7C618.014 121.7 622.481 122.7 626.348 124.7C630.214 126.7 633.281 129.467 635.548 133C637.881 136.467 639.181 140.467 639.448 145H621.648C621.448 142.8 620.614 140.933 619.148 139.4C617.748 137.867 615.681 137.1 612.948 137.1C610.614 137.1 608.681 137.7 607.148 138.9C605.681 140.1 604.581 141.767 603.848 143.9C603.114 146.033 602.748 148.567 602.748 151.5C602.748 154.233 603.048 156.667 603.648 158.8C604.314 160.933 605.381 162.6 606.848 163.8C608.381 164.933 610.414 165.5 612.948 165.5C614.814 165.5 616.381 165.167 617.648 164.5C618.914 163.833 619.914 162.9 620.648 161.7C621.381 160.433 621.848 158.967 622.048 157.3H639.848C639.581 161.967 638.248 166.067 635.848 169.6C633.514 173.133 630.381 175.9 626.448 177.9C622.514 179.9 618.014 180.9 612.948 180.9Z"
				fill="#050911"
			/>
			<path
				d="M649.076 178.9V123.7H663.476V147.7H663.276C663.276 139.967 664.876 133.9 668.076 129.5C671.342 125.1 676.009 122.9 682.076 122.9H684.276V138.5H680.076C676.009 138.5 672.842 139.6 670.576 141.8C668.376 144 667.276 147.167 667.276 151.3V178.9H649.076Z"
				fill="#050911"
			/>
			<path
				d="M694.059 178.9V123.7H712.259V178.9H694.059ZM687.059 136.9V123.7H712.259V136.9H687.059ZM701.559 119.3C698.225 119.3 695.759 118.467 694.159 116.8C692.625 115.067 691.859 112.867 691.859 110.2C691.859 107.533 692.625 105.367 694.159 103.7C695.759 101.967 698.192 101.1 701.459 101.1C704.792 101.1 707.225 101.967 708.759 103.7C710.292 105.367 711.059 107.533 711.059 110.2C711.059 112.867 710.292 115.067 708.759 116.8C707.225 118.467 704.825 119.3 701.559 119.3Z"
				fill="#050911"
			/>
			<path
				d="M761.238 180.7C756.705 180.7 752.738 179.767 749.338 177.9C746.005 176.033 743.372 173.3 741.438 169.7C739.572 166.1 738.572 161.7 738.438 156.5H740.038V178.9H725.638V105.9H743.838V143.5L739.838 147.5C740.038 142.1 741.072 137.5 742.938 133.7C744.805 129.9 747.372 127.033 750.638 125.1C753.972 123.1 757.805 122.1 762.138 122.1C765.872 122.1 769.238 122.8 772.238 124.2C775.238 125.533 777.772 127.467 779.838 130C781.972 132.467 783.605 135.4 784.738 138.8C785.872 142.2 786.438 145.933 786.438 150V152.4C786.438 156.467 785.872 160.233 784.738 163.7C783.605 167.1 781.938 170.1 779.738 172.7C777.538 175.233 774.872 177.2 771.738 178.6C768.672 180 765.172 180.7 761.238 180.7ZM756.038 165.7C758.438 165.7 760.538 165.1 762.338 163.9C764.205 162.633 765.638 160.9 766.638 158.7C767.705 156.5 768.238 154 768.238 151.2C768.238 148.333 767.705 145.867 766.638 143.8C765.638 141.667 764.238 140.033 762.438 138.9C760.638 137.7 758.505 137.1 756.038 137.1C753.838 137.1 751.772 137.6 749.838 138.6C747.972 139.6 746.438 141.067 745.238 143C744.038 144.933 743.438 147.267 743.438 150V153.4C743.438 156 744.072 158.233 745.338 160.1C746.605 161.9 748.205 163.3 750.138 164.3C752.072 165.233 754.038 165.7 756.038 165.7Z"
				fill="#050911"
			/>
			<path
				d="M822.339 180.9C817.672 180.9 813.539 180.1 809.939 178.5C806.339 176.9 803.306 174.733 800.839 172C798.372 169.267 796.472 166.2 795.139 162.8C793.872 159.4 793.239 155.9 793.239 152.3V150.3C793.239 146.567 793.872 143 795.139 139.6C796.472 136.133 798.339 133.067 800.739 130.4C803.206 127.733 806.206 125.633 809.739 124.1C813.272 122.5 817.272 121.7 821.739 121.7C827.672 121.7 832.706 123.033 836.839 125.7C840.972 128.367 844.139 131.9 846.339 136.3C848.539 140.633 849.639 145.367 849.639 150.5V156.3H800.839V146.5H839.139L832.839 150.9C832.839 147.767 832.406 145.133 831.539 143C830.739 140.8 829.506 139.133 827.839 138C826.239 136.867 824.206 136.3 821.739 136.3C819.339 136.3 817.239 136.867 815.439 138C813.706 139.067 812.372 140.733 811.439 143C810.506 145.2 810.039 148 810.039 151.4C810.039 154.467 810.472 157.133 811.339 159.4C812.206 161.6 813.539 163.3 815.339 164.5C817.139 165.7 819.472 166.3 822.339 166.3C824.872 166.3 826.939 165.867 828.539 165C830.206 164.133 831.372 162.967 832.039 161.5H848.639C847.839 165.233 846.239 168.567 843.839 171.5C841.506 174.433 838.506 176.733 834.839 178.4C831.239 180.067 827.072 180.9 822.339 180.9Z"
				fill="#050911"
			/>
		</svg>
	);
};

export default Logo;
