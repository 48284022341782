const FeatureItem = ({ img, align, title, desc, featureList }) => {
	return align === "left" ? (
		<div className={`feature flex-row-${align}`}>
			<img src={img} className={`feature-img ${align}`} />
			<div className="feature-text">
				<span className="feature-title text-medium-sora">{title}</span>
				<p className="feature-desc opacity-7">{desc}</p>
				{featureList &&
					featureList.map((listItem, index) => (
						<div key={index} className="feature-list-item flex-row-left">
							<div className="feature-bullet bg-black" />
							<span className="opacity-7">{listItem}</span>
						</div>
					))}
			</div>
		</div>
	) : (
		<div className={`feature flex-row-${align}`}>
			<div className="feature-text desktop">
				<span className="feature-title text-medium-sora">{title}</span>
				<p className="feature-desc opacity-7">{desc}</p>
				{featureList &&
					featureList.map((listItem, index) => (
						<div key={index} className="feature-list-item flex-row-left">
							<div className="feature-bullet bg-black" />
							<span className="opacity-7">{listItem}</span>
						</div>
					))}
			</div>
			<img src={img} className={`feature-img ${align}`} />
			<div className="feature-text mobile">
				<span className="feature-title text-medium-sora">{title}</span>
				<p className="feature-desc opacity-7">{desc}</p>
				{featureList &&
					featureList.map((listItem, index) => (
						<div key={index} className="feature-list-item flex-row-left">
							<div className="feature-bullet bg-black" />
							<span className="opacity-7">{listItem}</span>
						</div>
					))}
			</div>
		</div>
	);
};

export default FeatureItem;
