const LogoWhite = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 201 27" fill="none">
			<path
				d="M20.6996 5.41208C24.9813 1.13047 29.8763 2.30388 32.7174 3.94292C33.4929 4.39032 33.5429 5.4341 32.9098 6.06719L17.397 21.58C13.1154 25.8616 8.22034 24.6882 5.37923 23.0491C4.60371 22.6017 4.55379 21.5579 5.18687 20.9249L20.6996 5.41208Z"
				fill="#FD9A39"
			/>
			<path
				d="M15.832 5.97752C16.426 6.57153 16.426 7.5346 15.832 8.12861L4.11132 19.8493C3.47824 20.4824 2.43445 20.4325 1.98706 19.6569C0.348019 16.8158 -0.825392 11.9208 3.45622 7.63917L9.39939 1.69599C9.9934 1.10199 10.9565 1.10199 11.5505 1.69599L15.832 5.97752Z"
				fill="#F65854"
			/>
			<path
				d="M26.538 25.3042C27.132 25.8982 28.0951 25.8982 28.6891 25.3042L34.6404 19.3529C38.9221 15.0713 37.7486 10.1762 36.1096 7.33509C35.6622 6.55958 34.6184 6.50965 33.9853 7.14274L22.2565 18.8716C21.6625 19.4656 21.6625 20.4287 22.2565 21.0227L26.538 25.3042Z"
				fill="#27B3E8"
			/>
			<path
				d="M53.6373 21.524C51.8714 21.524 50.3826 21.247 49.1708 20.693C47.9763 20.139 47.0674 19.3859 46.4442 18.4338C45.8383 17.4643 45.5353 16.3564 45.5353 15.1099H50.2095C50.2095 15.56 50.3307 15.9755 50.5731 16.3564C50.8327 16.7199 51.2136 17.0056 51.7156 17.2133C52.2177 17.4211 52.8582 17.5249 53.6373 17.5249C54.3124 17.5249 54.8837 17.4384 55.3511 17.2653C55.8358 17.0921 56.1994 16.8498 56.4418 16.5382C56.7014 16.2266 56.8313 15.8543 56.8313 15.4216C56.8313 14.8676 56.5976 14.4434 56.1301 14.1491C55.6627 13.8375 54.875 13.6384 53.7671 13.5519L52.5466 13.448C50.5904 13.2749 49.0323 12.6603 47.8724 11.6043C46.7125 10.5483 46.1326 9.14603 46.1326 7.39754C46.1326 6.1511 46.4182 5.08642 46.9895 4.20352C47.5781 3.32062 48.4004 2.64546 49.4565 2.17804C50.5125 1.71063 51.7589 1.47692 53.1958 1.47692C54.7539 1.47692 56.0696 1.73659 57.1429 2.25595C58.2335 2.7753 59.0645 3.51105 59.6358 4.4632C60.2071 5.39804 60.4927 6.48868 60.4927 7.73512H55.8185C55.8185 7.31964 55.7147 6.93878 55.5069 6.59255C55.2992 6.24631 54.9962 5.97798 54.5981 5.78755C54.2172 5.57981 53.7498 5.47594 53.1958 5.47594C52.6764 5.47594 52.235 5.5625 51.8714 5.73562C51.5252 5.89142 51.2569 6.11647 51.0665 6.41077C50.8933 6.70507 50.8068 7.034 50.8068 7.39754C50.8068 7.88227 50.9712 8.29776 51.3002 8.64399C51.6291 8.99023 52.2004 9.19797 53.014 9.26722L54.2345 9.37109C55.6541 9.49227 56.9092 9.80388 57.9998 10.3059C59.0905 10.7907 59.9474 11.4572 60.5706 12.3054C61.1938 13.1537 61.5055 14.1924 61.5055 15.4216C61.5055 16.6507 61.1852 17.724 60.5446 18.6415C59.9214 19.5591 59.0212 20.2689 57.844 20.7709C56.6841 21.2729 55.2819 21.524 53.6373 21.524Z"
				fill="white"
			/>
			<path
				d="M68.5993 21.472C66.972 21.472 65.7082 20.944 64.808 19.888C63.9251 18.8147 63.4837 17.1787 63.4837 14.9801V6.67045H68.2098V15.1099C68.2098 15.7505 68.4002 16.2698 68.7811 16.668C69.1619 17.0662 69.6726 17.2653 70.3132 17.2653C70.9537 17.2653 71.473 17.0662 71.8712 16.668C72.2694 16.2698 72.4685 15.7332 72.4685 15.058V6.67045H77.1946V21.0046H73.4552V14.9801H73.7149C73.7149 16.4516 73.5245 17.6721 73.1436 18.6415C72.7801 19.5937 72.2348 20.3035 71.5077 20.7709C70.7806 21.2383 69.8804 21.472 68.807 21.472H68.5993Z"
				fill="white"
			/>
			<path
				d="M80.6844 26.1981V6.67045H84.4237V12.8508H84.0083C84.0602 11.4485 84.3372 10.254 84.8392 9.26722C85.3586 8.28045 86.0597 7.53604 86.9426 7.034C87.8255 6.51464 88.8382 6.25497 89.9808 6.25497C90.9676 6.25497 91.8591 6.43674 92.6555 6.80029C93.4518 7.14652 94.1356 7.64857 94.7069 8.30641C95.2782 8.94695 95.711 9.70867 96.0053 10.5916C96.3169 11.4745 96.4727 12.4439 96.4727 13.4999V14.1232C96.4727 15.1792 96.3342 16.1573 96.0573 17.0575C95.7803 17.9404 95.3648 18.7194 94.8108 19.3946C94.2568 20.0525 93.573 20.5632 92.7594 20.9267C91.963 21.2902 91.0455 21.472 90.0068 21.472C88.8988 21.472 87.9207 21.2556 87.0724 20.8228C86.2242 20.3727 85.549 19.7062 85.047 18.8233C84.5622 17.9231 84.3026 16.7978 84.2679 15.4475L85.4105 16.8498V26.1981H80.6844ZM88.5786 17.5769C89.2018 17.5769 89.7471 17.4211 90.2145 17.1095C90.6993 16.7805 91.0715 16.3304 91.3311 15.7591C91.6081 15.1878 91.7466 14.5387 91.7466 13.8116C91.7466 13.0672 91.6081 12.4266 91.3311 11.89C91.0715 11.336 90.7079 10.9118 90.2405 10.6175C89.7731 10.3059 89.2191 10.1501 88.5786 10.1501C88.0073 10.1501 87.4706 10.28 86.9686 10.5396C86.4838 10.7993 86.0857 11.1802 85.7741 11.6822C85.4624 12.1843 85.3066 12.7902 85.3066 13.4999V14.3828C85.3066 15.058 85.4711 15.638 85.8 16.1227C86.129 16.5901 86.5444 16.9536 87.0465 17.2133C87.5485 17.4557 88.0592 17.5769 88.5786 17.5769Z"
				fill="white"
			/>
			<path
				d="M105.795 21.524C104.584 21.524 103.51 21.3162 102.575 20.9007C101.641 20.4852 100.853 19.9226 100.212 19.2128C99.5717 18.503 99.0784 17.7067 98.7321 16.8238C98.4032 15.9409 98.2387 15.032 98.2387 14.0972V13.5778C98.2387 12.6084 98.4032 11.6822 98.7321 10.7993C99.0784 9.8991 99.5631 9.10276 100.186 8.41028C100.827 7.71781 101.606 7.17249 102.523 6.77432C103.441 6.35884 104.48 6.1511 105.64 6.1511C107.18 6.1511 108.487 6.49733 109.561 7.1898C110.634 7.88228 111.456 8.7998 112.028 9.94238C112.599 11.0676 112.885 12.2968 112.885 13.6298V15.1359H100.212V12.5911H110.158L108.522 13.7337C108.522 12.92 108.409 12.2362 108.184 11.6822C107.977 11.1109 107.656 10.6781 107.224 10.3838C106.808 10.0895 106.28 9.94238 105.64 9.94238C105.016 9.94238 104.471 10.0895 104.004 10.3838C103.553 10.6608 103.207 11.0936 102.965 11.6822C102.722 12.2535 102.601 12.9806 102.601 13.8635C102.601 14.6598 102.714 15.3523 102.939 15.9409C103.164 16.5122 103.51 16.9536 103.978 17.2653C104.445 17.5769 105.051 17.7327 105.795 17.7327C106.453 17.7327 106.99 17.6201 107.405 17.3951C107.838 17.17 108.141 16.8671 108.314 16.4862H112.625C112.417 17.4557 112.002 18.3213 111.378 19.083C110.772 19.8447 109.993 20.442 109.041 20.8748C108.106 21.3076 107.024 21.524 105.795 21.524Z"
				fill="white"
			/>
			<path
				d="M122.335 21.524C120.569 21.524 119.08 21.247 117.868 20.693C116.674 20.139 115.765 19.3859 115.142 18.4338C114.536 17.4643 114.233 16.3564 114.233 15.1099H118.907C118.907 15.56 119.028 15.9755 119.271 16.3564C119.53 16.7199 119.911 17.0056 120.413 17.2133C120.915 17.4211 121.556 17.5249 122.335 17.5249C123.01 17.5249 123.581 17.4384 124.049 17.2653C124.533 17.0921 124.897 16.8498 125.139 16.5382C125.399 16.2266 125.529 15.8543 125.529 15.4216C125.529 14.8676 125.295 14.4434 124.828 14.1491C124.36 13.8375 123.573 13.6384 122.465 13.5519L121.244 13.448C119.288 13.2749 117.73 12.6603 116.57 11.6043C115.41 10.5483 114.83 9.14603 114.83 7.39754C114.83 6.1511 115.116 5.08642 115.687 4.20352C116.276 3.32062 117.098 2.64546 118.154 2.17804C119.21 1.71063 120.457 1.47692 121.893 1.47692C123.452 1.47692 124.767 1.73659 125.841 2.25595C126.931 2.7753 127.762 3.51105 128.333 4.4632C128.905 5.39804 129.19 6.48868 129.19 7.73512H124.516C124.516 7.31964 124.412 6.93878 124.205 6.59255C123.997 6.24631 123.694 5.97798 123.296 5.78755C122.915 5.57981 122.447 5.47594 121.893 5.47594C121.374 5.47594 120.933 5.5625 120.569 5.73562C120.223 5.89142 119.955 6.11647 119.764 6.41077C119.591 6.70507 119.504 7.034 119.504 7.39754C119.504 7.88227 119.669 8.29776 119.998 8.64399C120.327 8.99023 120.898 9.19797 121.712 9.26722L122.932 9.37109C124.352 9.49227 125.607 9.80388 126.697 10.3059C127.788 10.7907 128.645 11.4572 129.268 12.3054C129.891 13.1537 130.203 14.1924 130.203 15.4216C130.203 16.6507 129.883 17.724 129.242 18.6415C128.619 19.5591 127.719 20.2689 126.542 20.7709C125.382 21.2729 123.98 21.524 122.335 21.524Z"
				fill="white"
			/>
			<path
				d="M139.037 21.524C137.79 21.524 136.708 21.3249 135.791 20.9267C134.873 20.5112 134.103 19.9572 133.48 19.2648C132.874 18.5723 132.415 17.7846 132.103 16.9017C131.809 16.0188 131.662 15.1013 131.662 14.1491V13.6298C131.662 12.6257 131.818 11.6736 132.129 10.7733C132.458 9.87313 132.934 9.07679 133.558 8.38432C134.181 7.69185 134.951 7.14652 135.869 6.74835C136.786 6.35018 137.842 6.1511 139.037 6.1511C140.352 6.1511 141.512 6.41077 142.516 6.93013C143.521 7.44948 144.317 8.16792 144.905 9.08544C145.511 9.98566 145.849 11.0244 145.918 12.2016H141.296C141.244 11.6303 141.028 11.1455 140.647 10.7474C140.283 10.3492 139.747 10.1501 139.037 10.1501C138.431 10.1501 137.929 10.3059 137.531 10.6175C137.15 10.9291 136.864 11.3619 136.674 11.9159C136.483 12.4699 136.388 13.1277 136.388 13.8895C136.388 14.5992 136.466 15.2311 136.622 15.7851C136.795 16.3391 137.072 16.7719 137.453 17.0835C137.851 17.3778 138.379 17.5249 139.037 17.5249C139.522 17.5249 139.928 17.4384 140.257 17.2653C140.586 17.0921 140.846 16.8498 141.036 16.5382C141.227 16.2092 141.348 15.8284 141.4 15.3956H146.022C145.953 16.6074 145.607 17.6721 144.983 18.5896C144.377 19.5071 143.564 20.2256 142.542 20.7449C141.521 21.2643 140.352 21.524 139.037 21.524Z"
				fill="white"
			/>
			<path
				d="M148.418 21.0046V6.67045H152.158V12.9027H152.106C152.106 10.8945 152.521 9.31915 153.352 8.17658C154.201 7.034 155.412 6.46271 156.988 6.46271H157.559V10.5137H156.468C155.412 10.5137 154.59 10.7993 154.001 11.3706C153.43 11.9419 153.145 12.7642 153.145 13.8375V21.0046H148.418Z"
				fill="white"
			/>
			<path
				d="M160.099 21.0046V6.67045H164.826V21.0046H160.099ZM158.282 10.0982V6.67045H164.826V10.0982H158.282ZM162.047 5.52787C161.181 5.52787 160.541 5.31148 160.125 4.87868C159.727 4.42858 159.528 3.85729 159.528 3.16482C159.528 2.47234 159.727 1.90971 160.125 1.47692C160.541 1.02681 161.173 0.801758 162.021 0.801758C162.887 0.801758 163.518 1.02681 163.917 1.47692C164.315 1.90971 164.514 2.47234 164.514 3.16482C164.514 3.85729 164.315 4.42858 163.917 4.87868C163.518 5.31148 162.895 5.52787 162.047 5.52787Z"
				fill="white"
			/>
			<path
				d="M177.544 21.472C176.367 21.472 175.337 21.2297 174.454 20.7449C173.589 20.2602 172.905 19.5504 172.403 18.6156C171.918 17.6807 171.658 16.5382 171.624 15.1878H172.039V21.0046H168.3V2.04821H173.026V11.812L171.987 12.8508C172.039 11.4485 172.308 10.254 172.792 9.26722C173.277 8.28045 173.944 7.53604 174.792 7.034C175.657 6.51464 176.653 6.25497 177.778 6.25497C178.748 6.25497 179.622 6.43674 180.401 6.80029C181.18 7.14652 181.838 7.64857 182.374 8.30641C182.928 8.94695 183.352 9.70867 183.647 10.5916C183.941 11.4745 184.088 12.4439 184.088 13.4999V14.1232C184.088 15.1792 183.941 16.1573 183.647 17.0575C183.352 17.9404 182.92 18.7194 182.348 19.3946C181.777 20.0525 181.085 20.5632 180.271 20.9267C179.475 21.2902 178.566 21.472 177.544 21.472ZM176.194 17.5769C176.817 17.5769 177.363 17.4211 177.83 17.1095C178.315 16.7805 178.687 16.3304 178.947 15.7591C179.224 15.1878 179.362 14.5387 179.362 13.8116C179.362 13.0672 179.224 12.4266 178.947 11.89C178.687 11.336 178.323 10.9118 177.856 10.6175C177.389 10.3059 176.835 10.1501 176.194 10.1501C175.623 10.1501 175.086 10.28 174.584 10.5396C174.099 10.7993 173.701 11.1802 173.39 11.6822C173.078 12.1843 172.922 12.7902 172.922 13.4999V14.3828C172.922 15.058 173.087 15.638 173.416 16.1227C173.744 16.5901 174.16 16.9536 174.662 17.2133C175.164 17.4557 175.675 17.5769 176.194 17.5769Z"
				fill="white"
			/>
			<path
				d="M193.411 21.524C192.199 21.524 191.126 21.3162 190.191 20.9007C189.256 20.4852 188.468 19.9226 187.828 19.2128C187.187 18.503 186.694 17.7067 186.348 16.8238C186.019 15.9409 185.854 15.032 185.854 14.0972V13.5778C185.854 12.6084 186.019 11.6822 186.348 10.7993C186.694 9.8991 187.179 9.10276 187.802 8.41028C188.442 7.71781 189.221 7.17249 190.139 6.77432C191.056 6.35884 192.095 6.1511 193.255 6.1511C194.796 6.1511 196.103 6.49733 197.176 7.1898C198.249 7.88228 199.072 8.7998 199.643 9.94238C200.214 11.0676 200.5 12.2968 200.5 13.6298V15.1359H187.828V12.5911H197.773L196.137 13.7337C196.137 12.92 196.025 12.2362 195.8 11.6822C195.592 11.1109 195.272 10.6781 194.839 10.3838C194.424 10.0895 193.896 9.94238 193.255 9.94238C192.632 9.94238 192.086 10.0895 191.619 10.3838C191.169 10.6608 190.823 11.0936 190.58 11.6822C190.338 12.2535 190.217 12.9806 190.217 13.8635C190.217 14.6598 190.329 15.3523 190.554 15.9409C190.779 16.5122 191.126 16.9536 191.593 17.2653C192.061 17.5769 192.666 17.7327 193.411 17.7327C194.069 17.7327 194.605 17.6201 195.021 17.3951C195.454 17.17 195.757 16.8671 195.93 16.4862H200.24C200.033 17.4557 199.617 18.3213 198.994 19.083C198.388 19.8447 197.609 20.442 196.657 20.8748C195.722 21.3076 194.64 21.524 193.411 21.524Z"
				fill="white"
			/>
		</svg>
	);
};

export default LogoWhite;
