import { useState, useRef } from "react";
import { useSnackbar } from "react-simple-snackbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Subscribe = () => {
	const inputRef = useRef();
	const [sending, setSending] = useState(false);
	const [email, setEmail] = useState("");

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSending(true);

		const checkResponse = await fetch(
			`${process.env.REACT_APP_SENDGRID_URI}/v3/marketing/contacts/search`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_KEY}`,
				},
				body: JSON.stringify({
					query: `email LIKE '${email}' AND CONTAINS(list_ids, '${process.env.REACT_APP_SENDGRID_LIST_ID}')`,
				}),
			}
		);

		const data = await checkResponse.json();
		if (!checkResponse.ok) {
			dangerSnackbar("Something went wrong, please try again.");
		} else {
			if (data.contact_count !== 0) {
				dangerSnackbar("That email is already in our waitlist.");
			} else {
				const response = await fetch(
					`${process.env.REACT_APP_SENDGRID_URI}/v3/marketing/contacts`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_KEY}`,
						},
						body: JSON.stringify({
							contacts: [{ email: email }],
							list_ids: [process.env.REACT_APP_SENDGRID_LIST_ID],
						}),
					}
				);
				if (!response.ok) {
					dangerSnackbar("Something went wrong, please try again.");
				} else {
					successSnackbar("You've been added to our waitlist!");
					setEmail("");
					inputRef.current.blur();
				}
			}
		}
		setSending(false);
	};

	return (
		<section className="subscribe flex-column">
			<span className="subscribe-title text-bold-sora">Join Our Waitlist</span>
			<p className="subscribe-desc text-sm opacity-7 align-center">
				Lorem ipsum dolor sit, amet consectetur adipisicing elit error accusamus
				molestiae.
			</p>
			<Form
				onSubmit={handleSubmit}
				className="subscribe-form col-lg-5 col-md-7 col-12"
			>
				<div className="subscribe-input flex-row-left">
					<Form.Control
						ref={inputRef}
						required
						type="email"
						className="text-sm"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Button
						type="submit"
						variant="dark"
						className="text-sm"
						disabled={sending}
					>
						{sending ? "Joining.." : "Join"}
					</Button>
				</div>
			</Form>
		</section>
	);
};

export default Subscribe;
