import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FacebookIcon from "../components/FacebookIcon";
import InstagramIcon from "../components/InstagramIcon";
import TwitterIcon from "../components/TwitterIcon";
import LogoWhite from "../components/LogoWhite";

const Footer = () => {
	return (
		<section className="footer bg-black">
			<Container className="col-lg-7 col-md-9 col-11">
				<Row className="flex-row-left">
					<Col className="footer-logo-container col-md-6 col-12">
						<LogoWhite className="footer-logo" />
						<span className="footer-text-mobile opacity-5 text-white label-sm">
							Laborum maxime rerum neque quis recusandae
						</span>
					</Col>
					<Col className="footer-social-container flex-row-right col-md-6 col-12">
						<a
							href="facebook.com"
							target="_blank"
							className="footer-social-link"
						>
							<FacebookIcon className="footer-social-icon" />
						</a>
						<a
							href="instagram.com"
							target="_blank"
							className="footer-social-link"
						>
							<InstagramIcon className="footer-social-icon" />
						</a>
						<a
							href="twitter.com"
							target="_blank"
							className="footer-social-link"
						>
							<TwitterIcon className="footer-social-icon" />
						</a>
					</Col>
				</Row>
				<Row className="footer-text-desktop">
					<Col>
						<span className="opacity-5 text-white label-sm">
							Laborum maxime rerum neque quis recusandae
						</span>
					</Col>
				</Row>
				<div className="footer-separator bg-white opacity-3 full"></div>
				<Row className="footer-bottom-container">
					<Col className="col-md-6 col-12">
						<span className="footer-bottom-text opacity-5 text-white label-sm">
							Laborum maxime rerum neque quis recusandae
						</span>
					</Col>
					<Col className="flex-row-right col-md-6 col-12">
						<span className="opacity-5 text-white label-sm">
							Lorem | Ipsum | Dolor
						</span>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Footer;
