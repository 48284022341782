import Hero from "../components/Hero";
import ForCreators from "../components/ForCreators";
import Features from "../components/Features";
import About from "../components/About";
import Faq from "../components/Faq";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";

const Home = () => {
	return (
		<>
			<Hero />
			<ForCreators />
			<Features />
			<About />
			<Faq />
			<Subscribe />
			<Footer />
		</>
	);
};

export default Home;
