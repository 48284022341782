import { React } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "remixicon/fonts/remixicon.css";
import "./index.css";

import Home from "./pages/Home";

const root = createRoot(document.getElementById("root"));

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Home />} />
				<Route path="supescribe-landing" element={<Home />} />
			</Routes>
		</BrowserRouter>
	);
}

root.render(
	<SnackbarProvider>
		<App />
	</SnackbarProvider>
);
