import avatars from "../assets/avatars.png";
import Container from "react-bootstrap/Container";

const ForCreators = () => {
	return (
		<section className="for-creators bg-black">
			<div className="creators-side-bg bg-black" />
			<Container className="full-height flex-row-center">
				<div className="for-creators-content">
					<span className="section-title for-creators-title text-bold-sora text-white block">
						Supescribe
					</span>
					<span className="section-title for-creators-title text-bold-sora text-white block">
						for Creators
					</span>
					<p className="for-creators-desc opacity-7 text-white">
						Pariatur quo et sit doloribus enim. Rerum eum repudiandae voluptate
						atque est voluptatem esse et
					</p>
				</div>
				<img src={avatars} className="creator-avatars" />
			</Container>
		</section>
	);
};

export default ForCreators;
