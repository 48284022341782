import featureGraphicsLeft from "../assets/feature-graphics-left.svg";
import featureGraphicsRight from "../assets/feature-graphics-right.svg";
import feature1 from "../assets/feature1.png";
import feature2 from "../assets/feature2.png";
import feature3 from "../assets/feature3.png";
import feature4 from "../assets/feature4.png";

import FeatureItem from "./FeatureItem";

const Features = () => {
	return (
		<section className="features">
			<div className="features-header flex-row-center">
				<img src={featureGraphicsLeft} className="feature-graphics" />
				<div className="features-title-container flex-column">
					<span className="features-title text-bold-sora section-title">
						Awesome Features
					</span>
					<span className="features-title text-bold-sora section-title">
						Designed for Creators
					</span>
				</div>
				<img src={featureGraphicsRight} className="feature-graphics" />
			</div>
			<div className="features-content">
				<FeatureItem
					img={feature1}
					align="left"
					title="Maximize your engagement"
					desc="Laborum maxime rerum neque quis recusandae recusandae omnis explicabo."
					featureList={[
						"Laborum maxime rerum neque",
						"Laborum maxime rerum neque",
						"Laborum maxime rerum neque",
					]}
				/>
				<FeatureItem
					img={feature2}
					align="right"
					title="Get detailed analytics and data"
					desc="Laborum maxime rerum neque quis recusandae recusandae omnis explicabo."
					featureList={[
						"Laborum maxime rerum neque",
						"Laborum maxime rerum neque",
						"Laborum maxime rerum neque",
					]}
				/>
				<FeatureItem
					img={feature3}
					align="left"
					title="Grow your audience"
					desc="Laborum maxime rerum neque quis recusandae recusandae omnis explicabo."
					featureList={[
						"Laborum maxime rerum neque",
						"Laborum maxime rerum neque",
						"Laborum maxime rerum neque",
					]}
				/>
				<FeatureItem
					img={feature4}
					align="right"
					title="Collaborate with other creators"
					desc="Laborum maxime rerum neque quis recusandae recusandae omnis explicabo."
				/>
			</div>
		</section>
	);
};

export default Features;
