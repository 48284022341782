import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Faq = () => {
	return (
		<section className="faq">
			<Container className="col-lg-7 col-md-9 col-11">
				<Row>
					<Col className="col-md-6 col-12">
						<span className="faq-title text-bold-sora">
							Frequently Asked Questions
						</span>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col className="col-md-6 col-12">
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis neque quis neque quis recusandae?
						</p>
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis recusandae?
						</p>
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis neque quis neque quis recusandae?
						</p>
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis recusandae?
						</p>
					</Col>
					<Col className="col-md-6 col-12">
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis recusandae?
						</p>
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis neque quis neque quis recusandae?
						</p>
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis recusandae?
						</p>
						<p className="faq-item text-sm opacity-7">
							Laborum maxime rerum neque quis neque quis neque quis recusandae?
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Faq;
