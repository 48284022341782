import Button from "react-bootstrap/Button";

const About = () => {
	return (
		<section className="about flex-column">
			<span className="about-title text-bold-sora section-title text-white">
				What is Supescribe
			</span>
			<p className="about-desc opacity-7 text-white align-center col-lg-5 col-md-8 col-12">
				Rerum animi dignissimos illum dolorem consectetur. Velit odit quaerat
				qui. Autem praesentium soluta quas dolores aspernatur. Neque sit libero
				totam quasi nostrum ut sit qui dolor sit id corrupti. Voluptatibus
				dolores nostrum maxime amet impedit eligendi ab tempore non veniam.
			</p>
			<div className="about-action bg-black flex-column col-lg-7 col-md-9 col-11">
				<span className="about-action-title text-white text-bold-sora">
					Ride the Wave!
				</span>
				<p className="about-action-desc text-white align-center opacity-7">
					Laborum maxime rerum neque quis recusandae recusandae omnis explicabo.
				</p>
				<Button
					variant="light"
					className="about-btn rounded text-medium-sora text-sm"
				>
					Get Started
				</Button>
			</div>
		</section>
	);
};

export default About;
