const TwitterIcon = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 26 21" fill="none">
			<path
				d="M25.1154 3.10945C24.1923 3.57099 23.2692 3.72483 22.1923 3.87868C23.2692 3.2633 24.0385 2.34022 24.3462 1.10945C23.4231 1.72483 22.3462 2.03253 21.1154 2.34022C20.1923 1.41714 18.8077 0.801758 17.4231 0.801758C14.1923 0.801758 11.7308 3.87868 12.5 6.9556C8.34615 6.80176 4.65385 4.80176 2.03846 1.72484C0.653846 4.03253 1.42308 6.9556 3.57692 8.49407C2.80769 8.49407 2.03846 8.18637 1.26923 7.87868C1.26923 10.1864 2.96154 12.3402 5.26923 12.9556C4.5 13.1094 3.73077 13.2633 2.96154 13.1095C3.57692 15.1095 5.42308 16.6479 7.73077 16.6479C5.88462 18.0325 3.11539 18.8018 0.5 18.4941C2.80769 19.8787 5.42308 20.8018 8.19231 20.8018C17.5769 20.8018 22.8077 12.9556 22.5 5.72483C23.5769 5.10945 24.5 4.18637 25.1154 3.10945Z"
				fill="white"
			/>
		</svg>
	);
};

export default TwitterIcon;
