const FacebookIcon = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 21 21" fill="none">
			<path
				d="M20.5 10.8018C20.5 5.30176 16 0.801758 10.5 0.801758C5 0.801758 0.5 5.30176 0.5 10.8018C0.5 15.8018 4.125 19.9268 8.875 20.6768V13.6768H6.375V10.8018H8.875V8.55176C8.875 6.05176 10.375 4.67676 12.625 4.67676C13.75 4.67676 14.875 4.92676 14.875 4.92676V7.42676H13.625C12.375 7.42676 12 8.17676 12 8.92676V10.8018H14.75L14.25 13.6768H11.875V20.8018C16.875 20.0518 20.5 15.8018 20.5 10.8018Z"
				fill="white"
			/>
		</svg>
	);
};

export default FacebookIcon;
